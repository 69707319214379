<template>
  <v-container>
    <partners-list
      :headers="headers"
      :data="data"
      :response="response"
      :loading="loading"
      :canUserDelete="canUserDelete"
      @edit="editFn"
      @delete="deleteFn"
      @new="newFn"
      @reload="loadDealers"
    ></partners-list>
    <delete-dialog
      :dialog="deleteDialog"
      :item="itemName"
      @agree="remove"
      v-show="false"
    ></delete-dialog>
  </v-container>
</template>

<script>
import api from "@/utils/api.js";
import PartnersList from "@/components/lists/PartnersList.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import snackbar from "@/utils/snackbar.js";
import { USER_CRM_REQUEST } from "@/store/actions/user";
import { COMPUTE_CAN_USER_WRITE_PARTNERS_PERMISSION } from "@/store/actions/permissions";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    PartnersList,
    DeleteDialog,
  },
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "left",
        sortable: false,
        value: "name",
      },
      {
        text: "Users",
        align: "left",
        value: "users",
      },
      {
        text: "Boats",
        align: "left",
        value: "boats",
      },
      {
        text: "Country",
        align: "left",
        value: "country",
      },
      {
        text: "City",
        align: "left",
        value: "city",
      },
      { text: "Actions", align: "right", value: "action", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    data: [],
    sort: "name",
    response: null,
    loading: true,
    itemName: null,
    deleteDialog: false,
    canUserDelete: true,
  }),
  created() {
    this.loadDealers();
    this.$store.dispatch(COMPUTE_CAN_USER_WRITE_PARTNERS_PERMISSION);
  },
  computed: {
    ...mapGetters(["getMe", "getCanUserWritePartners"]),
  },
  // beforeRouteEnter(to, from, next) {
  //   if (typeof to.query !== "undefined" && to.query.special === true) {
  //     next({
  //       name: "PartnersEdit",
  //       params: { id: to.query.id, tab: to.query.tab },
  //     });
  //   } else {
  //     next();
  //   }
  // },

  methods: {
    loadDealers(params) {
      if (!params) {
        params = {};
      }
      this.loading = true;
      this.data = [];
      api.getDealerList(params).then((resp) => {
        this.response = resp.data;
        this.data = resp.data.results;
        this.canUserDelete = this.data.length > 1;
        this.loading = false;
      });
    },
    editFn(dealer) {
      this.$router.push({
        name: "PartnersEdit",
        params: { id: dealer.uuid, tab: "info" },
      });
    },
    deleteFn(item) {
      this.deleteDialog = true;
      this.item = item;
      this.itemName = item.name;
    },
    remove(agreed) {
      if (agreed) {
        api.deleteDealer(this.item.uuid).then((resp) => {
          snackbar.success("Successfully deleted");
          this.loadDealers();
        });
      }
      this.deleteDialog = false;
    },
    // previewFn(dealer) {
    //   this.$router.push({
    //     name: "PartnersDetails",
    //     params: { id: dealer.uuid },
    //   });
    // },
    newFn(item) {
      this.$router.push({
        name: "PartnersNew",
        params: { tab: "info" },
      });
    },
  },
};
</script>
