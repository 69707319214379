var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 text-overflow my-10",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"hide-default-footer":"","options":_vm.options,"server-items-length":24,"items-per-page":24,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"uuid","show-expand":""},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.handleClickRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"70"}},[_c('v-toolbar-title',{staticClass:"headline"},[_c('span',[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('v-spacer'),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitSearch($event)}}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":"","autocomplete":"new-password"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),(_vm.getCanUserWritePartners)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.newItem()}}},[_vm._v("Add new ")]):_vm._e()],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.boats.length !== 0)?_c('td',{staticClass:"ml-0 pl-0 mr-0 pr-0",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"ml-0 pl-0 mr-0 pr-0 pb-3",staticStyle:{"border-radius":"0 !important"},attrs:{"color":"grey lighten-3","flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-subtitle',{staticClass:"caption font-weight-bold pl-12 ml-6"},[_vm._v(" Boats ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-subtitle',{staticClass:"caption font-weight-bold mr-12 float-right"},[_vm._v(" Actions ")])],1)],1),_vm._l((item.boats),function(boat,index){return _c('div',{key:index},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"4"}},[_c('v-list-item',{attrs:{"single-line":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-list-item-action',[_c('v-checkbox',{key:boat.uuid,attrs:{"color":"primary","value":boat.uuid},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(boat.boat_name)+" ")])],1)]}}],null,true)})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"8"}},[_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.transferBoats(_vm.selected, item, boat)}}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-transfer")]),_vm._v(" Transfer")],1)],1)],1)],1)})],2)],1):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2",staticStyle:{"height":"40px"},attrs:{"onclick":"event.stopPropagation()"}},[_c('v-icon',{staticClass:"mx-2 edit--hover",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil ")])],1)]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [(item.user_count)?_c('span',[_vm._v(_vm._s(item.user_count))]):_vm._e()]}},{key:"item.boats",fn:function(ref){
var item = ref.item;
return [(item.boats)?_c('span',[_vm._v(_vm._s(item.boats.length))]):_vm._e()]}}])}),_c('v-pagination',{attrs:{"length":_vm.numberOfPages,"total-visible":7},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),(_vm.transferBoatDialog.active)?_c('transfer-boat-dialog',{attrs:{"dialog":_vm.transferBoatDialog},on:{"save":_vm.handleTransfer}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }