<template>
  <v-row justify="center">
    <v-dialog v-model="dialog.active" persistent width="500">
      <v-card>
        <v-card-title class="headline">Transfer boat</v-card-title>
        <v-card-text>Selected Boat:</v-card-text>
        <v-card-text
          class="font-weight-bold body-2"
          v-for="(boat, index) in dialog.boats"
          :key="index"
        >
          {{ boat.boat_name }}
        </v-card-text>
        <v-card-text class="pb-0">
          <v-select
            class="pt-4"
            label="Transfer to"
            :items="getPartnerOptions"
            v-model="partner"
            item-text="name"
            item-value="uuid"
          ></v-select>
        </v-card-text>
        <v-card-text class="mt-0"
          ><b
            >Existing booking, tasks and messages associated with these boats
            will be archived. Existing guest access will not be affected.</b
          ></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onDisagree()">{{ deny }}</v-btn>
          <v-btn color="primary" text @click="onAgree()">{{ approve }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      partner: null,
    };
  },
  computed: {
    approve() {
      return typeof this.confirm == "undefined" ? "Transfer" : this.confirm;
    },
    deny() {
      return typeof this.reject == "undefined" ? "Cancel" : this.reject;
    },
    getBoatUuids() {
      return this.dialog.boats.map(({ uuid }) => uuid);
    },
    getPartnerOptions() {
      return this.dialog.partners;
    },
  },

  methods: {
    onAgree() {
      this.$emit("save", {
        crm_boat_uuids: this.getBoatUuids,
        partner: this.partner,
      });
    },
    onDisagree() {
      this.$emit("save", false);
    },
  },
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
