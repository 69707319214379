<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="data"
      :loading="loading"
      hide-default-footer
      :options.sync="options"
      :server-items-length="24"
      :items-per-page="24"
      class="elevation-1 text-overflow my-10"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="uuid"
      show-expand
      @click:row="handleClickRow"
    >
      <template v-slot:top>
        <v-toolbar flat height="70">
          <v-toolbar-title class="headline">
            <span>
              {{ title }}
            </span>
          </v-toolbar-title>
          <v-spacer />
          <v-form @submit.prevent="submitSearch">
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              autocomplete="new-password"
            />
          </v-form>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="newItem()"
            v-if="getCanUserWritePartners"
            >Add new
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="ml-0 pl-0 mr-0 pr-0"
          v-if="item.boats.length !== 0"
        >
          <v-card
            color="grey lighten-3"
            flat
            class="ml-0 pl-0 mr-0 pr-0 pb-3"
            style="border-radius: 0 !important"
          >
            <v-row no-gutters>
              <v-col cols="6">
                <v-card-subtitle class="caption font-weight-bold pl-12 ml-6">
                  Boats
                </v-card-subtitle>
              </v-col>
              <v-col cols="6">
                <v-card-subtitle
                  class="caption font-weight-bold mr-12 float-right"
                >
                  Actions
                </v-card-subtitle>
              </v-col>
            </v-row>
            <div v-for="(boat, index) in item.boats" :key="index">
              <v-row no-gutters>
                <v-col cols="4" class="mt-2">
                  <v-list-item single-line>
                    <template v-slot:default="{}">
                      <v-list-item-action>
                        <v-checkbox
                          color="primary"
                          v-model="selected"
                          :value="boat.uuid"
                          :key="boat.uuid"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ boat.boat_name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-col>
                <v-col cols="8" class="mt-5">
                  <v-btn
                    color="primary"
                    @click="transferBoats(selected, item, boat)"
                    text
                    class="float-right"
                    ><v-icon class="mx-2">mdi-transfer</v-icon> Transfer</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-card>
        </td>
      </template>
      <template v-slot:item.action="{ item }">
        <div style="height:40px" class="pt-2" onclick="event.stopPropagation()">
          <v-icon class="mx-2 edit--hover" @click="editItem(item)"
            >mdi-pencil
          </v-icon>
        </div>
      </template>
      <template v-slot:item.users="{ item }">
        <span v-if="item.user_count">{{ item.user_count }}</span>
      </template>

      <template v-slot:item.boats="{ item }">
        <span v-if="item.boats">{{ item.boats.length }}</span>
      </template>
    </v-data-table>
    <v-pagination
      v-model="currentPage"
      :length="numberOfPages"
      :total-visible="7"
    />
    <transfer-boat-dialog
      v-if="transferBoatDialog.active"
      :dialog="transferBoatDialog"
      @save="handleTransfer"
    ></transfer-boat-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TransferBoatDialog from "@/components/dialogs/TransferBoatDialog.vue";
import api from "@/utils/api.js";

export default {
  props: {
    title: String,
    headers: Array,
    data: Array,
    sort: String,
    loading: Boolean,
    response: Object,
    canUserDelete: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TransferBoatDialog,
  },

  data: () => ({
    selected: [],
    expanded: [],
    singleExpand: true,
    options: { sortBy: [], sortDesc: [], page: [], itemsPerPage: 24 },
    search: "",
    page: 1,
    transferBoatDialog: { active: false, boats: [], partners: [] },
  }),

  created() {
    if (this.$route.query.page || this.$route.query.search) {
      this.search = this.$route.query.search;
      this.currentPage = Number(this.$route.query.page);
    }
  },

  watch: {
    options: {
      handler(value) {
        this.$emit("sort", value);
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(["getMe", "getCanUserWritePartners"]),

    numberOfPages() {
      if (!this.response) {
        return 0;
      }
      return parseInt(Math.ceil(this.response.count / 24));
    },
    currentPage: {
      get: function() {
        return this.page;
      },
      set: function(value) {
        this.page = value;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let ordering = "";
        if (sortBy.length !== 0 && sortDesc.length !== 0) {
          ordering = `${sortDesc[0] === true ? "-" : ""}${sortBy[0]}`;
        }
        this.$emit("reload", {
          search: this.search,
          page: value,
          ordering: ordering,
        });
        this.$router.replace({
          name: this.$route.name,
          query: {
            page: this.page,
            search: this.search,
          },
        });
      },
    },
  },
  methods: {
    handleClickRow(item, event) {
      if (event.isExpanded) {
        const index = this.expanded.findIndex((i) => i === item);
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(item);
      }
    },
    handleTransfer(event) {
      if (!event) {
        this.transferBoatDialog = {
          active: false,
          boats: [],
          partners: [],
        };
        return;
      }
      const { partner, crm_boat_uuids } = event;

      api.transferBoats(partner, { crm_boat_uuids }).then((value) => {
        this.transferBoatDialog = { active: false, boats: [], partners: [] };
        this.$emit("reload", { search: this.search, page: this.page });
      });
    },

    transferBoats(selected, partner, boat) {
      let boats = partner.boats.filter((element) => {
        return selected.includes(element.uuid);
      });

      this.transferBoatDialog = {
        ...this.transferBoatDialog,
        boats: boats.length === 0 ? [boat] : boats,
        partners: this.data.filter((item) => {
          return item.uuid !== partner.uuid;
        }),
        active: true,
      };
    },
    submitSearch(event) {
      this.page = 1;
      this.$router.replace({
        name: this.$route.name,
        query: {
          page: this.page,
          search: this.search,
        },
      });
      this.$emit("reload", { search: this.search, page: this.page });
      return false;
    },
    editItem(item) {
      this.$emit("edit", item);
    },
    deleteItem(item) {
      this.$emit("delete", item);
    },
    // previewItem(item) {
    //   this.$emit("details", item);
    // },
    newItem() {
      this.$emit("new", {});
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
.delete--hover {
  &:hover {
    color: red;
  }
}

.preview--hover {
  &:hover {
    color: var(--primary);
  }
}

.edit--hover {
  &:hover {
    color: var(--primary);
  }
}
.copy--hover {
  &:hover {
    color: var(--primary);
  }
}
</style>
